import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { Playlist as PlaylistType } from "../types/Playlist";
import Playlist from "./Playlist";

const Playlists = ({ socket }: any) => {
  const [playlists, setPlaylists] = useState<PlaylistType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleAddPlaylist = (e: any) => {
    console.log("adding playlist");
    e.preventDefault();
    const validChain = /https:\/\/open\.spotify\.com\/playlist\/w*/.test(
      e.target.playlist.value
    );
    if (validChain) {
      setErrorMessage(null);
      let playlist = e.target.playlist.value.replace(
        "https://open.spotify.com/playlist/",
        ""
      );
      playlist = playlist.split("?")[0];
      socket.emit("addPlaylist", playlist);
      e.target.reset();
    } else {
      setErrorMessage("La URL no es válida");
    }
  };

  const handleDelete = (playlist: PlaylistType) => {
    Swal.fire({
      title: "Estas seguro?",
      text: "No vas a poder revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, borrala!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setPlaylists(
          playlists.filter((p: PlaylistType) => p.id !== playlist.id)
        );
        Swal.fire("Borrado!", "Tu playlist fue borrada.", "success");
      }
    });
  };

  useEffect(() => {
    socket.on("playlistAdded", (playlist: PlaylistType) => {
      setPlaylists(playlists.concat(playlist));
      Swal.fire("Agregada!", "Tu playlist fue agregada.", "success");
    });
    if (playlists.length > 0)
      window.localStorage.setItem("playlists", JSON.stringify(playlists));
  }, [playlists]);

  useEffect(() => {
    setPlaylists(JSON.parse(window.localStorage.getItem("playlists") || "[]"));
  }, []);
  return (
    <Row>
      <Col sm={12} className="mt-5 mb-5">
        <h3>Agregar playlists</h3>
        <small className="mb-3">Tiene que ser pública de Spotify...</small>
        {errorMessage && <h5 className="text-danger">{errorMessage}</h5>}
        <Form onSubmit={handleAddPlaylist}>
          <Form.Control
            className="mb-3"
            type="text"
            placeholder="Ingrese una playlist..."
            name="playlist"
            required
          ></Form.Control>
          <Button type="submit">Agregar</Button>
        </Form>
      </Col>
      {playlists.length > 0 && (
        <>
          <h3>Mis listas</h3>
          {playlists.map((playlist: PlaylistType) => (
            <Playlist
              key={playlist.id}
              playlist={playlist}
              handleDelete={handleDelete}
            />
          ))}
        </>
      )}
    </Row>
  );
};

export default Playlists;
