import { Badge, Button, Card, Col, Image } from "react-bootstrap";
import { Trash, Spotify } from "react-bootstrap-icons";
import { Playlist as PlaylistType } from "../types/Playlist";
import styled from "styled-components";
import { useState } from "react";

type PlaylistProps = {
  playlist: PlaylistType;
  handleDelete?: (playlist: PlaylistType) => void;
  handleClick?: (playlist: PlaylistType, selected: boolean) => void;
};

const SpotifyIcon = styled(Spotify)`
  margin-right: 0.5rem;
`;

const Overlay = styled(Card.ImgOverlay)`
  background-color: rgba(0, 0, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Span = styled.span`
  font-size: 3rem;
`;

const Playlist = ({ playlist, handleDelete, handleClick }: PlaylistProps) => {
  const [selected, setSelected] = useState<boolean>(false);
  const handleSelect = (playlist: PlaylistType) => {
    if (handleClick) {
      handleClick(playlist, !selected);
      setSelected(!selected);
    }
  };
  return (
    <Col key={playlist.id} sm={6} md={4} lg={3}>
      <Card
        onClick={() => (handleClick ? handleSelect(playlist) : null)}
        style={{ cursor: handleClick ? "pointer" : "default" }}
      >
        <Card.Header>
          <div className="d-flex align-items-center justify-content-between">
            <Card.Title>{playlist.name}</Card.Title>
            {handleDelete && (
              <Button variant="danger" onClick={() => handleDelete(playlist)}>
                <Trash />
              </Button>
            )}
          </div>
        </Card.Header>
        <Image src={playlist.images[0].url} />
        {selected && (
          <Overlay>
            <Badge bg="transparent" pill>
              <Span role="img" aria-label="check">
                ✔️
              </Span>
            </Badge>
          </Overlay>
        )}
        <a
          target="_blank"
          href={playlist.external_urls.spotify}
          className="btn btn-success mt-3 font-weight-bold"
          rel="noreferrer"
        >
          <SpotifyIcon />
          Spotify
        </a>
      </Card>
    </Col>
  );
};

export default Playlist;
