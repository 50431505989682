import React, { useState, useEffect } from "react";

function ProgressBar({ duration }: { duration: number }) {
  const [progress, setProgress] = useState(100);
  let progressForInterval = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (duration - progressForInterval <= 0) {
        clearInterval(interval);
        setProgress(100);
        return;
      }
      progressForInterval = progressForInterval + 0.1;
      setProgress(((duration - progressForInterval) / duration) * 100);
    }, 100);
    return () => clearInterval(interval);
  }, [duration]);

  const currentSecond = (duration / 100) * (100 - progress);

  return (
    <div className="d-flex justify-content-between">
      <div
        style={{
          width: "90%",
          border: "1px solid black",
        }}
      >
        <div
          style={{
            width: `${progress}%`,
            height: "100%",
            backgroundColor: "blue",
          }}
        ></div>
      </div>
      <div>{(duration - currentSecond).toFixed(2)}</div>
    </div>
  );
}

export default ProgressBar;
