import { useEffect, useRef, useState } from "react";
import { Card, Form } from "react-bootstrap";
import styled from "styled-components";

type Message = {
  user?: string;
  message: string;
};

const Wrapper = styled(Card)`
  height: 30vh;
  margin-bottom: 2rem;
  padding: 1rem;
  overflow-y: scroll;
`;

const ChatMessage = styled.p`
  margin-bottom: 0.5rem;
`;

const Chat = ({ socket }: any) => {
  const [messages, setMessages] = useState<Message[]>([]);

  const messageRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSubmitChat = (e: any) => {
    e.preventDefault();
    socket.emit("chatMessage", e.target.message.value);
    e.target.reset();
  };

  const getMessage = (message: Message, index: number) => {
    if (message.user) {
      return (
        <ChatMessage key={index}>
          <strong>{message.user}:</strong> {message.message}
        </ChatMessage>
      );
    }
    return (
      <ChatMessage key={index}>
        <em>{message.message}</em>
      </ChatMessage>
    );
  };

  useEffect(() => {
    socket.on("message", (message: Message) => {
      setMessages((messages) => [...messages, message]);
    });

    scrollToBottom();
    return () => {
      socket.off("message");
    };
  }, [messages, socket]);

  return (
    <>
      <Wrapper>
        {messages.map((message, index) => getMessage(message, index))}
        <div ref={messageRef} />
      </Wrapper>
      <Form onSubmit={handleSubmitChat}>
        <Form.Control
          type="text"
          name="message"
          placeholder="Enviar chat..."
          required
        ></Form.Control>
      </Form>
    </>
  );
};

export default Chat;
