import { useCallback, useEffect, useState } from "react";
import useDidMountEffect from "../hooks/useDidMountEffect";
import { Song } from "../types/Song";
import ProgressBar from "./ProgressBar";

const SongPlayer = ({
  song,
  socket,
  host,
}: {
  song: Song;
  socket: any;
  host: boolean;
}) => {
  const [audio, setAudio] = useState(new Audio(song.track.preview_url ?? ""));
  const [playing, setPlaying] = useState(true);

  const toggle = useCallback(() => setPlaying(!playing), [playing]);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener("ended", () => {
      console.log("pidiendo otra cancion");
      setPlaying(false);
      setTimeout(() => {
        if (host) socket.emit("nextSong");
      }, 5000);
    });
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, [host, audio, socket]);

  useDidMountEffect(() => {
    if (song) {
      setAudio(new Audio(song.track.preview_url ?? ""));
      setPlaying(true);
    }
  }, [song]);

  const duration = playing ? 30 : 5;

  return (
    <>
      <ProgressBar duration={duration} />
      {!playing && song && (
        <div className="card mb-3 mt-3">
          <div className="row g-0">
            <div className="col-sm-4">
              <img
                src={song.track.album.images[0].url}
                className="img-fluid rounded-start"
                alt="album-cover"
              />
            </div>
            <div className="col-sm-8">
              <div className="card-body">
                <h5 className="card-title">{song.track.name}</h5>
                <p className="card-text">
                  {song.track.artists.map((artist) => artist.name).join(", ")}
                </p>
                <p className="card-text">
                  {song.playlist} - {song.owner}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SongPlayer;
