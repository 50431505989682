/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Playlist as PlaylistType } from "../types/Playlist";
import { Song } from "../types/Song";
import { User } from "../types/User";
import Chat from "./Chat";
import Playlist from "./Playlist";
import SongPlayer from "./SongPlayer";

type RoomProps = {
  name: string;
  socket: any;
};

const Room = ({ name, socket }: RoomProps) => {
  const [users, setUsers] = useState<User[]>([]);
  const [ready, setReady] = useState<boolean>(false);
  const [playlistsSelected, setPlaylistsSelected] = useState<PlaylistType[]>(
    []
  );
  const [host, setHost] = useState<boolean>(false);
  const [playlists, setPlaylists] = useState<PlaylistType[]>([]);
  const [status, setStatus] = useState<string>(
    "Esperando que todos los jugadores estén listos"
  );
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [song, setSong] = useState<Song | null>(null);
  const [songs, setSongs] = useState<Song[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [turn, setTurn] = useState<number>(0);

  const handleSubmitGuess = useCallback((e: any) => {
    setIsValid(null);
    e.preventDefault();
    console.log("submitting guess");
    socket.emit("guess", {
      guess: e.target.guess.value,
    });
    e.target.reset();
  }, []);

  const handleReady = useCallback(() => {
    setReady(!ready);
    socket.emit("ready");
  }, [ready]);

  const handleClick = useCallback(
    (playlist: PlaylistType, selected: boolean) => {
      socket.emit("playlistSelected", { playlist, selected });
    },
    []
  );

  const handleStart = useCallback(() => {
    socket.emit("start");
    setIsPlaying(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowResults(false);
  }, []);

  useEffect(() => {
    socket.on("userList", (users: User[]) => {
      setUsers(users);
    });
    socket.on("userLeft", (users: User[]) => {
      setUsers(users);
    });
    socket.on("playlistsSelected", (playlists: PlaylistType[]) => {
      setPlaylistsSelected(playlists);
    });

    return () => {
      socket.off("userList");
      socket.off("userLeft");
      socket.off("playlistsSelected");
    };
  }, [users, playlistsSelected]);

  useEffect(() => {
    if (
      users.length >= 2 &&
      users.filter((user: User) => user.ready).length === users.length
    ) {
      if (isPlaying) {
        setStatus("");
        return;
      }
      if (!playlistsSelected.length)
        setStatus("Esperando a que el anfitrión seleccione las canciones");
      else setStatus("Esperando a que el anfitrión inicie la partida");
    } else setStatus("Esperando a que todos los jugadores estén listos");

    if (
      users.length &&
      users.filter((user: User) => user.host)[0].id === socket.id
    ) {
      setHost(true);
    }
  }, [users, playlistsSelected]);

  useEffect(() => {
    socket.on("playlists", (playlists: PlaylistType[]) => {
      setPlaylists(playlists);
    });

    return () => {
      socket.off("playlists");
    };
  }, [playlists]);

  useEffect(() => {
    socket.on("status", (message: string) => {
      setStatus(message);
    });

    return () => {
      socket.off("status");
    };
  }, [status]);

  useEffect(() => {
    socket.on("start", () => {
      setStatus("La partida ha comenzado");
      setIsPlaying(true);
    });

    socket.on("end", ({ users, songs }: { users: User[]; songs: Song[] }) => {
      setStatus("La partida ha terminado");
      setIsPlaying(false);
      setUsers(users);
      setShowResults(true);
      setPlaylistsSelected([]);
      setTurn(0);
      setSong(null);
      setSongs(songs);
    });

    return () => {
      socket.off("start");
      socket.off("end");
    };
  }, [isPlaying]);

  useEffect(() => {
    socket.on("noMatch", () => {
      setIsValid(false);
    });
    socket.on("correctAnswer", () => {
      setIsValid(true);
    });
    socket.on("match", ({ username }: { username: string }) => {
      setUsers((users) => {
        const updatedUsers = [...users];
        const user = updatedUsers.filter(
          (user: User) => user.username === username
        )[0];
        user.points = 1;
        return updatedUsers;
      });
    });

    socket.on(
      "finishLine",
      ({
        username,
        place,
        points,
      }: {
        username: string;
        place: number;
        points: number;
      }) => {
        setUsers((users) => {
          console.log("points", points);
          const updatedUsers = [...users];
          const user = updatedUsers.filter(
            (user: User) => user.username === username
          )[0];
          user.points = points;
          return updatedUsers;
        });
      }
    );

    return () => {
      socket.off("noMatch");
      socket.off("correctAnswer");
      socket.off("match");
      socket.off("finishLine");
    };
  }, [isValid, users]);

  useEffect(() => {
    socket.on(
      "song",
      ({ song, users: updatedUsers }: { song: Song; users: User[] }) => {
        setSong(song);
        setIsValid(null);
        setUsers(updatedUsers);
        setTurn((turn) => turn + 1);
      }
    );

    return () => {
      socket.off("song");
    };
  }, [song]);

  useEffect(() => {
    socket.emit("getClients");
  }, []);

  return (
    <Row>
      <Col sm={12} className="d-flex justify-content-between mb-3">
        <div>
          <h3>
            Sala: {name} {isPlaying ? `, turno ${turn}/15` : ""}{" "}
          </h3>
        </div>
        <div>
          {!isPlaying && (
            <Button
              variant={ready ? "danger" : "success"}
              onClick={handleReady}
            >
              {ready ? "No estoy listo" : "Estoy listo"}
            </Button>
          )}
          {!isPlaying &&
            host &&
            ready &&
            users.length >= 2 &&
            users.filter((user: User) => user.ready).length === users.length &&
            playlistsSelected.length > 0 && (
              <Button className="ml-2" variant="success" onClick={handleStart}>
                Iniciar Partida
              </Button>
            )}
        </div>
      </Col>
      <Col sm={12} className="mb-3">
        <Modal size="lg" show={showResults} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>¡La partida ha terminado!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Usuario</th>
                  <th>Puntaje</th>
                  <th>Adivinó</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user: User, index: number) => (
                  <tr key={user.id}>
                    <td>{index + 1}</td>
                    <td>{user.username}</td>
                    <td>{user.score}</td>
                    <td>{user.guessed}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <br />
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Canción</th>
                  <th>Playlist</th>
                  <th>Dueño</th>
                </tr>
              </thead>
              <tbody>
                {songs.map((item: Song, index: number) => (
                  <tr key={item.track.id}>
                    <td>{index + 1}</td>
                    <td>{item.track.name}</td>
                    <td>{item.playlist}</td>
                    <td>{item.owner}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <p>Canciones por usuario:</p>
            {users.map((user: User) => (
              <p key={user.username}>
                <b>{user.username}:</b>{" "}
                {
                  songs.filter((item: Song) => item.owner == user.username)
                    .length
                }
              </p>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
        {song && isPlaying && (
          <SongPlayer song={song} host={host} socket={socket} />
        )}
      </Col>
      <Col md={4}>
        <ListGroup as="ol">
          {users.map((user: User) => (
            <ListGroup.Item
              key={user.id}
              as="li"
              className="d-flex justify-content-between align-items-center"
            >
              <div>{user.host ? "👑" : ""}</div>
              <div className="ms-2 me-auto">
                <div className="fw-bold">{user.username}</div>
              </div>
              <Badge bg="transparent" pill className="text-dark">
                {user.ready && !isPlaying && (
                  <span role="img" aria-label="check">
                    ✔️
                  </span>
                )}
                {!user.ready && !isPlaying && (
                  <span role="img" aria-label="cross">
                    ❌
                  </span>
                )}
                {isPlaying
                  ? user.points
                    ? `${user.score} +${user.points}`
                    : user.score
                  : ""}
              </Badge>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
      <Col md={8}>
        <Form onSubmit={handleSubmitGuess} className="mb-3">
          <label htmlFor="guess" className="form-label">
            {status}
          </label>
          <Form.Control
            className={
              isValid != null
                ? isValid
                  ? "mb-3 is-valid"
                  : " mb-3 is-invalid"
                : "mb-3"
            }
            type="text"
            placeholder="Adivina el artista y/o la canción aquí"
            name="guess"
            required
          ></Form.Control>
        </Form>
        <Chat socket={socket} />
      </Col>
      {host && !isPlaying && (
        <Col md={12} className="mt-3">
          <h5>Elegí las playlists para jugar:</h5>
          {song && <p>{song.track.name}</p>}
          <Row>
            {playlists.map((playlist: PlaylistType) => (
              <Playlist
                key={playlist.id}
                playlist={playlist}
                handleClick={handleClick}
              />
            ))}
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default Room;
