import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import styled from "styled-components";
import Room from "./components/Room";

import { io } from "socket.io-client";
import Playlists from "./components/Playlists";

const URL = "http://back.macaco.com.ar/";
const socket = io(URL, { autoConnect: false });
socket.removeAllListeners();

const UsernameWrapper = styled(Row)`
  margin-top: 2rem;
`;

function App() {
  const [username, setUsername] = useState<string | null>(null);
  const [input, setInput] = useState<string>("");
  const [room, setRoom] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (event: React.BaseSyntheticEvent) => {
    setInput(event.currentTarget.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setUsername(input);
    setInput("");
  };

  const handleCreateRoom = (e: any) => {
    e.preventDefault();
    console.log("creating room");
    socket.emit("createRoom", {
      room: e.target.room.value,
      password: e.target.password.value,
      playlists: JSON.parse(window.localStorage.getItem("playlists") || "[]"),
    });
    e.target.reset();
  };

  const handleJoinRoom = (e: any) => {
    e.preventDefault();
    socket.emit("joinRoom", {
      room: e.target.room.value,
      password: e.target.password.value,
      playlists: JSON.parse(window.localStorage.getItem("playlists") || "[]"),
    });
    e.target.reset();
  };

  const handleLeave = () => {
    socket.emit("leaveRoom");
    setRoom(null);
  };

  useEffect(() => {
    if (username) {
      socket.auth = { username };
      socket.connect();
      window.localStorage.setItem("username", username);
    }
  }, [username]);

  useEffect(() => {
    socket.on("roomCreated", ({ validated, room, message }) => {
      if (!validated) {
        setErrorMessage(message);
        return;
      }
      setRoom(room);
    });
    socket.on("roomJoined", ({ validated, room, message }) => {
      if (!validated) {
        setErrorMessage(message);
        return;
      }
      setRoom(room);
    });
  }, [room]);

  useEffect(() => {
    setUsername(window.localStorage.getItem("username"));
    socket.on("disconnect", () => {
      setUsername(null);
      setRoom(null);
    });
    return () => {
      socket.off("connect_error");
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col className="text-center">
          <h1>G.T.S</h1>
          <h4>Guess The Song</h4>
        </Col>
      </Row>
      {username ? (
        <>
          <Row>
            <Col xs={12} className="d-flex justify-content-between">
              <div>
                <h2 className="mb-5">Hola! {username}</h2>
              </div>
              {room ? (
                <div>
                  <Button variant="danger" onClick={handleLeave}>
                    Abandonar Sala
                  </Button>
                </div>
              ) : null}
            </Col>
          </Row>
          {room ? (
            <Room name={room} socket={socket} />
          ) : (
            <>
              <Row>
                {errorMessage && (
                  <Col md={12} className="text-center">
                    <h4 className="text-danger">{errorMessage}</h4>
                  </Col>
                )}

                <Col md={{ span: 4, offset: 2 }} lg={{ span: 3, offset: 3 }}>
                  <Form onSubmit={handleCreateRoom}>
                    <h5>Crear una sala:</h5>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      placeholder="Nombre de la sala"
                      name="room"
                      required
                    ></Form.Control>
                    <h5>Contraseña:</h5>
                    <Form.Control
                      className="mb-3"
                      type="password"
                      placeholder="Contraseña"
                      name="password"
                      required
                    ></Form.Control>
                    <Button type="submit">Crear</Button>
                  </Form>
                </Col>
                <Col md={4} lg={3}>
                  <Form onSubmit={handleJoinRoom}>
                    <h5>Unirse a una sala:</h5>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      placeholder="Nombre de la sala"
                      name="room"
                      required
                    ></Form.Control>
                    <h5>Contraseña:</h5>
                    <Form.Control
                      className="mb-3"
                      type="password"
                      placeholder="Contraseña"
                      name="password"
                      required
                    ></Form.Control>
                    <Button type="submit">Unirse</Button>
                  </Form>
                </Col>
              </Row>
              <Playlists socket={socket} />
            </>
          )}
        </>
      ) : (
        <UsernameWrapper>
          <Col md={{ span: 4, offset: 4 }} className="text-center">
            <h5>Usuario: {username}</h5>
            <Form onSubmit={handleSubmit}>
              <Form.Control
                className="mb-3"
                type="text"
                placeholder="Ingrese un usuario..."
                name="username"
                required
                onChange={handleChange}
              ></Form.Control>
              <Button type="submit">Enviar</Button>
            </Form>
          </Col>
        </UsernameWrapper>
      )}
    </Container>
  );
}

export default App;
